import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../../atoms/Card'
import { Box, Container, Heading, Text, Flex, Button, HStack } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import { Helmet } from 'react-helmet'
import NavBar from '../../atoms/NavBar'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useAutomationTemplates } from '../../../hooks/useAutomationTemplates'
import ThemeGrid from './src/ThemeGrid'
import useQueryAttribute from '../../../hooks/useQueryAttribute'
import usePagination from '../../../hooks/usePagination'
import Pagination from '../../molecules/Pagination'
import FilterButtons from '../../molecules/FilterButtons'
import FooterBase from '../../molecules/FooterBase'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'

/**
 * Filters
 * */

const filterCollection = [
	{ value: 'all', label: 'All', colorScheme: 'blue' },
	{
		value: 'simple',
		themes: ['simple'],
		label: 'Simple',
		colorScheme: 'red',
	},
	{
		value: 'art',
		themes: [
			'school-spirit',
			'fossil-fun',
			'adorable',
			'wildflower-meadow',
			'lots-of-love',
			'celestial-celebration',
			'burst-of-fun',
			'floral',
			'watercolor',
			'Art Deco' /** 'fresh-in-bloom */,
		],
		label: 'Art Series',
		colorScheme: 'green',
	},
	{
		value: 'celebration',
		themes: ['rose-gold', 'razzle-dazzle', 'happy-birthday', 'gift-wrap' /** 'funfetti,glitterpop */],
		label: 'Celebration Series',
		colorScheme: 'purple',
	},
	{
		value: 'collage',
		themes: ['collage-light', 'collage', 'collage-dark'],
		label: 'Collage Series',
		colorScheme: 'orange',
	},
	{
		value: 'life',
		themes: ['above-the-clouds', 'foliage', 'letterpress', 'make-a-wish' /** Graffiti, serrenity */],
		label: 'Life Series',
		colorScheme: 'pink',
	},
	{
		value: 'holiday',
		themes: ['holly-jolly', 'happy-hanukkah', 'ugly-sweater', 'winter-breeze', 'holiday-glow', 'festive-collage'],
		label: 'Holiday Series',
		colorScheme: 'blue',
	},
	{
		value: 'school',
		themes: ['class-of-2024', 'class-of-2023', 'class-of-2022', 'school-spirit'],
		label: 'School Series',
		colorScheme: 'teal',
	},
]

const SceneryTheme = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const eventHandlers = useEventHandlersV2(event.uuid)
	const { uuid } = useParams()
	const navigate = useNavigate()
	/**
	 * Theme Automation
	 */
	const { selectableTemplates, isThemeAvailable, currentTheme } = useAutomationTemplates(event)

	/**
	 * Hold reference to the query parameter
	 * */
	const filter = useQueryAttribute({ key: 'filter', defaultValue: 'all' })

	const [filteredCollection, setFilteredCollection] = useState([])

	useEffect(() => {
		if (selectableTemplates && selectableTemplates.length > 0) {
			if (filter) {
				let _filter = filterCollection.find((el) => el.value == filter)
				let _collection =
					_filter.value != 'all'
						? selectableTemplates.filter((el) => _filter.themes.includes(el.class))
						: selectableTemplates
				setFilteredCollection(_collection)
			} else setFilteredCollection(selectableTemplates)
		}
	}, [filter, selectableTemplates])

	/**
	 * Save the selected thumbnail
	 * */
	const [clickedThemeId, setClickedThemeId] = useState(null)

	/**
	 * Pagination
	 */
	const paginationInstance = usePagination({ collection: filteredCollection })
	const { slice, handlers, pagination } = paginationInstance

	const handleScrollTop = (e) => {
		e.preventDefault()
		if (typeof window != 'undefined') window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return (
		<>
			<Helmet>
				<title>VidDay - Video Theme</title>
			</Helmet>

			<NavBar position="fixed">
				<HStack spacing={['4%', '1rem']}>
					<BackButton onClick={() => navigate(eventHandlers.customize)} />
				</HStack>
			</NavBar>

			<Container maxW="container.xl" py="1rem" variant="main">
				<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Video Theme
					</Heading>
					<Text>Choose a video theme to set the right mood.</Text>
				</Box>

				<Card px={['1rem', '2rem']} py="2rem" variant="main">
					<FilterButtons
						collection={filterCollection}
						target={`/event/${uuid}/theme`}
						activeValue={filter}
						keyName="filter"
						itemOnClick={handlers.goFirst}
					/>
					<ThemeGrid collection={slice} currentTheme={currentTheme} />
				</Card>

				<Pagination pagination={pagination} handlers={handlers} my="2rem" />

				<Flex my="1rem" w="full" justify="center">
					<Button variant="ghost" onClick={handleScrollTop}>
						Back to Top
					</Button>
				</Flex>
			</Container>

			<FooterBase />
		</>
	)
}

export default SceneryTheme
