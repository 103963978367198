import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, SimpleGrid } from '@chakra-ui/react'
import ThemeItem from './ThemeItem'

const ThemeGrid = ({ collection, currentTheme }) => {
	const navigate = useNavigate()
	const { uuid } = useParams()

	const items = collection.map((el, i) => {
		return (
			<ThemeItem
				key={i}
				item={el}
				isSelected={currentTheme.id == el.id}
				onClick={() => navigate(`/event/${uuid}/scenery/theme/${el.id}`)}
			/>
		)
	})

	return (
		<Box w="full">
			<SimpleGrid columns={[2, 2, 3, 4]} spacing="1rem">
				{items}
			</SimpleGrid>
		</Box>
	)
}

export default ThemeGrid
